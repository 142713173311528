.menu {
    position: fixed;
    display: block;
    width: 100%;
    top: 50px;
    right: 0;
    background-color: #FFFFFF;
    z-index: 1100;

    @media screen and (min-width: 768px) {
      width: 364px;
      top: 80px;
      box-shadow: 3px 8px 14px rgba(136, 198, 253, 0.19);
    }
  }

  .nav {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .userInfo {
    padding: 16px;
    border-bottom: 1px solid #a3c5d3;
    overflow: hidden;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .userName {
    font-weight: 700;
    font-size: 18px;
    color: #ff9900;
    white-space: nowrap;         /* Не дозволяє тексту переноситись на новий рядок */
    overflow: hidden;            /* Ховає текст, що виходить за межі контейнера */
    text-overflow: ellipsis;     /* Додає три крапки, якщо текст не вміщається */
  }

  .userEmail {
    padding-top: 10px;
    font-weight: 500;
    white-space: nowrap;         /* Не дозволяє тексту переноситись на новий рядок */
    overflow: hidden;            /* Ховає текст, що виходить за межі контейнера */
    text-overflow: ellipsis;     /* Додає три крапки, якщо текст не вміщається */
  }

  .userLevel {
    padding-top: 10px;
    font-weight: 700;
    color: #0d30f5;
  }

  .userLevelNum {
    font-weight: 700;
    color: #000000;
  }

  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #ff9900;
    color: #FFFFFF;
    text-align: center;
    font-size: 12px;
  }

.link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 16px;
    font-weight: 700;
    color: #2a363b;   
  }
  
.link:hover,  
.link:focus,
.link:active {
    color: #0d30f5;
    background-color: beige;
  }

.logout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

  .officeLink li:last-child {
    border-top: 1px solid #a3c5d3;
  }