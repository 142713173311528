.bonusWrapper {
    padding-top: 70px;
    padding-bottom: 40px;
  
    @media screen and (min-width: 768px) {
      padding-top: 100px;
      padding-left: 30px;
      padding-right: 30px;
    }
}

.title {
  margin-bottom: 30px;
  font-size: 22px;
  text-align: left;
  
  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
}

.menu {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.menuBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  padding: 5px 10px;
  font-size: 15px;
  background-color: beige;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.bonusMenu {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.bonusMenu.active {
  display: block;
  margin-top: 10px;

  @media screen and (min-width: 768px) {
    margin-top: 0px;
  }
}

.bonusNav {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: #FFFFFF;
  border-radius: 10px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}
  
.bonusLink {
  width: 100%;
  padding: 13px;
  font-size: 15px;
  font-weight: 600;
  color: #000000;

  @media screen and (min-width: 768px) {
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 8px;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.bonusLink:hover,
.bonusLink:focus,
.bonusLink:active {
  background-color: beige;
}

.history {
  position: relative;
}

.historyMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: fit-content;
}

.historyMenu:hover,  
.historyMenu:focus {
  background-color: beige;
}

.icon {
  display: block;
  width: 24px;
  height: 24px;
}