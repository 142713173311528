@import-normalize;

html {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #fef9f9;
}

html, body {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

code {
  font-family: 'Manrope', sans-serif;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
} */

h1 {
  color: #333;
  font-size: 32px;
  font-weight: 700;
}

h2 {
  color: #555;
  font-size: 20px;
  font-weight: 700;
}

h3 {
  color: #555;
  font-size: 18px;
  font-weight: 700;
}

p {
  color: #555;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  padding: 0;
  font-style: normal;
  text-decoration: none;
}

button {
  font-family: 'Manrope', sans-serif;
  outline: transparent;
  border: none;
  cursor: pointer;
}

.container {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 480px;
  
  @media screen and (min-width: 480px) {
    max-width: 480px;
  }

  @media screen and (min-width: 768px) {
    max-width: 768px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
}

.section {
  margin: 0 auto;
}

.no-scroll {
  max-width: 100vw;
  overflow-y: hidden;
}
