.headerContainer {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 480px;
  padding: 0 10px;
  background-color: #fef9f9;
  z-index: 1200;

  @media screen and (min-width: 768px) {
    max-width: 768px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #d7d2f3;

  @media screen and (min-width: 768px) {
    height: 80px;
  }
}

.menuBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 1200px) {
    gap: 120px;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 1200px) {
    gap: 30px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.textLogo {
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: calc(20/18);
  color: #0d5ef5;
}
  
  .officeMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (min-width: 1200px) {
      width: 250px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .officeMenu:hover,  
  .officeMenu:focus {
    background-color: beige;
    cursor: pointer;
  }

  .officeUser {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    overflow: hidden;

    @media screen and (min-width: 1200px) {
      gap: 10px;
    }
  }

  .name {
    font-size: 14px;
    white-space: nowrap;         /* Не дозволяє тексту переноситись на новий рядок */
    overflow: hidden;            /* Ховає текст, що виходить за межі контейнера */
    text-overflow: ellipsis;     /* Додає три крапки, якщо текст не вміщається */

    @media screen and (min-width: 1200px) {
      font-size: 16px;
    }
  }

  .menu {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .button {
    display: inline-block;
    width: auto;
    height: 50px;
    padding: 0 20px;
    border: 1px solid #ffffff;
    border-radius: 20px;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
    line-height: calc(16/14);
    overflow: hidden;
    outline: none;
    cursor: pointer;
    color: #000000;
    background-color: rgb(255, 244, 0);
  }
  
  .button:hover,
  .button:focus {
    background-color: rgb(228, 228, 0);
  }

    .overlay {
      position: fixed;
      top: 50;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #fef9f9cc;
      z-index: 1000;

      @media screen and (min-width: 768px) {
        top: 80;
      }
    }