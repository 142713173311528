.menu {
    position: absolute;
    display: block;
    width: 100%;
    top: 40px;
    right: 0;
    background-color: #FFFFFF;
    box-shadow: 3px 8px 14px rgba(136, 198, 253, 0.19);
    border-top: 1px solid #a3c5d3;
    z-index: 100;
}

.historyLink {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 20px;
    font-weight: 500;
    color: #2a363b;   
}
  
.historyLink:hover,  
.historyLink:focus,
.historyLink:active {
    color: #0d30f5;
    background-color: rgb(245, 245, 245);
}
  